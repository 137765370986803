.sidenav {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-image: url("../../images/navbackground.svg");
  border-bottom: 1px solid #1cffd6;
  overflow-x: hidden;
  padding-top: 180px;
  padding-left: 30px;
  transition: 0.5s;
}

.nav-items {
  margin-top: 20px;
  margin-bottom: 50px;
}

.socials {
  position: absolute;
  bottom: 40px;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  z-index: 99999;
}

.socials > * {
  width: 40px;
}

body {
  padding: 0;
  margin: 0;
}

code {
  font-family: "Inter";
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

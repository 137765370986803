nav {
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.algomint-logo {
  width: 200px;
  height: 100px;
  cursor: pointer;
}

.menu-icon {
  cursor: pointer;
  z-index: 999999;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 2px;
  background-color: rgb(202, 199, 199);
  margin: 8px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-405deg) translate(-4px, 3px);
  transform: rotate(-405deg) translate(-4px, 3px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

@media only screen and (max-width: 550px) {
  .algomint-logo {
    width: 150px;
    height: 200px;
  }
  nav {
    position: sticky;
  }
}

.accordion-wrapper {
  border-radius: 20px;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: all 1s cubic-bezier(0, 1, 0, 1);
  line-height: 1.6;
}

.accordion-content.show {
  height: auto;
  max-height: 9999px;
  transition: max-height 0.5s ease-in-out;
  padding: 0.8em;
}
